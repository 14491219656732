var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [
                _vm._v(
                  _vm._s(
                    this.routeSign == "add"
                      ? _vm.$t("物料反馈")
                      : _vm.$t("物料反馈")
                  ) + "\n      "
                )
              ]
            )
          ]),
          [
            _c(
              "div",
              [
                _c(
                  "el-form",
                  {
                    ref: "refForm",
                    attrs: {
                      model: _vm.formObj,
                      rules: _vm.formObjRules,
                      "label-position": "top"
                    }
                  },
                  [
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "left" } },
                      [_vm._v(_vm._s(_vm.$t("标准")))]
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            staticStyle: { display: "flex", width: "100%" },
                            attrs: { span: 10, sm: 20, md: 10 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { width: "20%" },
                                attrs: {
                                  label: _vm.$t("标准编号"),
                                  prop: "standardCode",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "请选择"
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.changeOptions($event),
                                          _vm.changeXiaLa()
                                      }
                                    },
                                    model: {
                                      value: _vm.formObj.standardCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formObj,
                                          "standardCode",
                                          $$v
                                        )
                                      },
                                      expression: "formObj.standardCode"
                                    }
                                  },
                                  _vm._l(_vm.parameterStandardList, function(
                                    item
                                  ) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.code,
                                        value: {
                                          value: item.code,
                                          code: item.code
                                        }
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            this.routeSign !== "update"
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "20%" },
                                    attrs: {
                                      label: _vm.$t("标准名称"),
                                      prop: "standardName",
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          disabled: "",
                                          placeholder: "请选择"
                                        },
                                        model: {
                                          value: _vm.formObj.standardName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formObj,
                                              "standardName",
                                              $$v
                                            )
                                          },
                                          expression: "formObj.standardName"
                                        }
                                      },
                                      _vm._l(
                                        _vm.parameterStandardList,
                                        function(item) {
                                          return _c("el-option", {
                                            key: item.name,
                                            attrs: {
                                              label: item.name,
                                              value: item.name
                                            }
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            staticStyle: { display: "flex", width: "100%" },
                            attrs: { span: 10, sm: 20, md: 10 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { width: "20%" },
                                attrs: {
                                  label: _vm.$t("采购订单号"),
                                  prop: "purchaseNo",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "220px" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.formObj.purchaseNo,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "purchaseNo", $$v)
                                    },
                                    expression: "formObj.purchaseNo"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { width: "20%" },
                                attrs: {
                                  label: _vm.$t("物料编号"),
                                  prop: "bomNo",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "220px" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.formObj.bomNo,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "bomNo", $$v)
                                    },
                                    expression: "formObj.bomNo"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { width: "20%" },
                                attrs: {
                                  label: _vm.$t("产品名称"),
                                  prop: "productName",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "220px" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.formObj.productName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "productName", $$v)
                                    },
                                    expression: "formObj.productName"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { width: "20%" },
                                attrs: {
                                  label: _vm.$t("样本大小"),
                                  prop: "sampleSize",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "220px" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.formObj.sampleSize,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "sampleSize", $$v)
                                    },
                                    expression: "formObj.sampleSize"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { width: "20%" },
                                attrs: {
                                  label: _vm.$t("颜色"),
                                  prop: "colour",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "220px" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.formObj.colour,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "colour", $$v)
                                    },
                                    expression: "formObj.colour"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "smallTit" }, [
                      _vm._v(_vm._s(_vm.$t("物料信息")))
                    ]),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.formObj.itemTable,
                          "row-class-name": _vm.tableRowClassName,
                          "header-cell-class-name": _vm.starAdd
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("Index"),
                            type: "index",
                            width: "50px",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("参数编号"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".parameterCode"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          disabled: true
                                        },
                                        model: {
                                          value: scope.row.parameterCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "parameterCode",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.parameterCode"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        this.routeSign !== "update"
                          ? _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("参数名称"),
                                align: "center"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "itemTable." +
                                                scope.$index +
                                                ".parameterName"
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                disabled: true
                                              },
                                              model: {
                                                value: scope.row.parameterName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "parameterName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.parameterName"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                309747716
                              )
                            })
                          : _vm._e(),
                        false
                          ? _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("参数类型"),
                                align: "center"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "itemTable." +
                                                scope.$index +
                                                ".type"
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                disabled: true
                                              },
                                              model: {
                                                value: scope.row.type,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "type",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.type"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2341567204
                              )
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("合格范围"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".realityMaterial"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          disabled: true
                                        },
                                        model: {
                                          value: scope.row.realityMaterial,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "realityMaterial",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scope.row.realityMaterial"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("检验值"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".inspectionMaterial"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: { clearable: "" },
                                          on: {
                                            change: function($event) {
                                              return _vm.verification(
                                                scope.row.type,
                                                scope.row.realityMaterial,
                                                scope.row.inspectionMaterial,
                                                scope.row.index
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row.inspectionMaterial,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "inspectionMaterial",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.inspectionMaterial"
                                          }
                                        },
                                        [_vm._v(">\n                  ")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("评价"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".evaluate"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { clearable: "" },
                                        model: {
                                          value: scope.row.evaluate,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "evaluate", $$v)
                                          },
                                          expression: "scope.row.evaluate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("是否符合标准"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".complianceWithStandards"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            disabled: scope.row.type !== 0
                                          },
                                          model: {
                                            value:
                                              scope.row.complianceWithStandards,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "complianceWithStandards",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.complianceWithStandards"
                                          }
                                        },
                                        _vm._l(_vm.options, function(item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "right",
                          padding: "50px 0"
                        }
                      },
                      [
                        this.routeSign !== "update"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.submitForm }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("Save")) + "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("el-button", { on: { click: _vm.resetForm } }, [
                          _vm._v(_vm._s(_vm.$t("Cancel")))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }