<!-- 视图 -->
<template>
  <div class="pvhFactory">
    <basic-container>
      <div class="layTop">
        <div class="Title" style="margin-bottom:20px;">{{
            this.routeSign == 'add' ? $t('物料反馈')
                : $t('物料反馈')
          }}
        </div>
      </div>
      <template>
        <div>
          <el-form :model="formObj" ref="refForm" :rules="formObjRules" label-position="top">
            <!--表头名称-->
            <el-divider content-position="left">{{ $t('标准') }}</el-divider>
            <el-row class="el-row_style" >
               <el-col :span="10" :sm="20" :md="10" class="el-col_style"  style="display: flex; width: 100%">
                   <el-form-item :label="$t('标准编号')" prop="standardCode" label-width="120px" style=" width: 20%" >
                     <el-select v-model="formObj.standardCode" filterable clearable placeholder="请选择" @change="changeOptions($event),changeXiaLa()">
                       <el-option v-for="item in parameterStandardList" :key="item.code" :label="item.code" :value="{value:item.code,code:item.code}">
                       </el-option>
                     </el-select>
                   </el-form-item>
                   <el-form-item :label="$t('标准名称')" prop="standardName" label-width="120px" style=" width: 20%" v-if="this.routeSign!=='update'">
                     <el-select v-model="formObj.standardName" filterable clearable disabled placeholder="请选择" >
                       <el-option v-for="item in parameterStandardList" :key="item.name" :label="item.name" :value="item.name">
                       </el-option>
                     </el-select>
                   </el-form-item>
               </el-col>
            </el-row>
            <el-row class="el-row_style" >
              <el-col :span="10" :sm="20" :md="10" class="el-col_style"  style="display: flex; width: 100%">
                  <el-form-item :label="$t('采购订单号')" prop="purchaseNo" label-width="120px"  style=" width: 20%">
                    <el-input v-model="formObj.purchaseNo" filterable clearable placeholder="请选择" style="width: 220px"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('物料编号')" prop="bomNo" label-width="120px"  style=" width: 20%">
                    <el-input v-model="formObj.bomNo" filterable clearable placeholder="请选择" style="width: 220px"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('产品名称')" prop="productName" label-width="120px"  style=" width: 20%">
                    <el-input v-model="formObj.productName" filterable clearable placeholder="请选择" style="width: 220px"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('样本大小')" prop="sampleSize" label-width="120px"  style=" width: 20%">
                    <el-input v-model="formObj.sampleSize" filterable clearable placeholder="请选择" style="width: 220px"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('颜色')" prop="colour" label-width="120px"  style=" width: 20%">
                  <el-input v-model="formObj.colour" filterable clearable placeholder="请选择" style="width: 220px"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!--表体名称-->
            <div class="smallTit">{{ $t('物料信息') }}</div>
            <el-table :data="formObj.itemTable" :row-class-name="tableRowClassName"
                      style="width: 100%"  :header-cell-class-name="starAdd">
              <el-table-column :label="$t('Index')" type="index" width="50px" align='center'>
              </el-table-column>
              <el-table-column :label="$t('参数编号')"  align='center'>
                <template slot-scope="scope">
                  <el-form-item :prop="'itemTable.' + scope.$index + '.parameterCode'">
                    <el-input  v-model="scope.row.parameterCode" clearable :disabled="true"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column :label="$t('参数名称')"  align='center' v-if="this.routeSign!=='update'">
                <template slot-scope="scope">
                  <el-form-item :prop="'itemTable.' + scope.$index + '.parameterName'">
                    <el-input  v-model="scope.row.parameterName" clearable :disabled="true"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column :label="$t('参数类型')"  align='center' v-if="false">
                <template slot-scope="scope">
                  <el-form-item :prop="'itemTable.' + scope.$index + '.type'">
                    <el-input  v-model="scope.row.type" clearable :disabled="true"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column :label="$t('合格范围')"  align='center'>
                <template slot-scope="scope">
                  <el-form-item :prop="'itemTable.' + scope.$index + '.realityMaterial'">
                    <el-input  v-model="scope.row.realityMaterial" clearable :disabled="true">
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column :label="$t('检验值')" align='center'>
                <template slot-scope="scope">
                  <el-form-item :prop="'itemTable.' + scope.$index + '.inspectionMaterial'">
                    <el-input  v-model="scope.row.inspectionMaterial" clearable
                               @change="verification(scope.row.type,scope.row.realityMaterial,scope.row.inspectionMaterial, scope.row.index)">>
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column :label="$t('评价')" align='center'>
                <template slot-scope="scope">
                  <el-form-item :prop="'itemTable.' + scope.$index + '.evaluate'">
                    <el-input  v-model="scope.row.evaluate" clearable>
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column :label="$t('是否符合标准')" align='center'>
                <template slot-scope="scope">
                  <el-form-item :prop="'itemTable.' + scope.$index + '.complianceWithStandards'">
                    <el-select  v-model="scope.row.complianceWithStandards" clearable :disabled="scope.row.type!==0">
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
            <div style="text-align:right;padding:50px 0;">
              <el-button type="primary"  @click="submitForm" v-if="this.routeSign!=='update'">{{$t('Save') }}
              </el-button>
              <el-button @click="resetForm">{{ $t('Cancel') }}</el-button>
            </div>
          </el-form>
        </div>
      </template>
    </basic-container>
  </div>
</template>
<script>
//引入对象
import {list, feedbackList} from "@/api/feedbackStandard";
import {add, chakan} from "@/api/materialFeedback";
export default {
  feedbackData: [],
  //data：返回实体对象
  data() {
    return {
      parameterStandardList:[],
      //路由传递信息
      routeSign: '', purchaseNo: '',bomNo:'',productName:'',sampleSize:'',colour:'',
      //表单信息
      formObj: {
        //表头信息
        standardName: '', standardCode: '',purchaseNo:'',bomNo:'',productName:'',sampleSize:'',colour:'',
        //表体信息
        itemTable: [],
      },
      formObjRules: {
        standardName: [{required: true,message: '请输入标准名称', trigger: 'blur'}],
        standardCode: [{required: true,message: '请输入标准编号', trigger: 'blur'}],
      },
      options: [{
        value: '1',
        label: '合格'
      }, {
        value: '2',
        label: '不合格'
      }],
    }
  },
  computed: {
    setData() {
      return {}
    }
  },
  created() {
    this.xiala();
    this.init();
    this.routeSign = this.$route.query.sign;
    if (this.routeSign==='update'){
      this.edit();
    }
  },
  watch: {
    $route() {
      this.init()
    }
  },
  methods: {
    //初始加载数据
    init() {
      this.routeSign = this.$route.query.sign;
      this.formObj.purchaseNo = this.$route.query.purchaseNo;
      this.formObj.bomNo = this.$route.query.bomNo;
      this.formObj.productName = this.$route.query.productName;
      this.formObj.sampleSize = this.$route.query.sampleSize;
      this.formObj.colour = this.$route.query.colour;
    },
    addrow() {
      let item = {
        Id: "",
        sampleSize: "",
        productName: "",
        bomNo: "",
        purchaseNo: "",
        colour:"",
        realityMaterial: "",
        standardCode: "",
        parameterCode: "",
        inspectionMaterial: "",
        complianceWithStandards: "",
        evaluate: "",
      }
      this.formObj.itemTable.push(item);
    },
    //提交数据
    submitForm() {
      this.$nextTick(() => {//避免点击两次
        let formName = "refForm";
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //新增
            console.log("this.formObj",this.formObj)
            var deetailList=[]
            this.formObj.itemTable.forEach(v => {
              let item = {
                "complianceWithStandards": v.complianceWithStandards === "合格" ? 1 : 2 ,
                "inspectionMaterial": v.inspectionMaterial,
                "evaluate": v.evaluate,
                "parameterCode": v.parameterCode,
                "realityMaterial": v.realityMaterial,
              }
              this.formObj.itemTable.push(item);
              deetailList.push(item);
            })
            var info= {
              sampleSize: this.formObj.sampleSize,
              productName: this.formObj.productName,
              bomNo: this.formObj.bomNo,
              purchaseNo: this.formObj.purchaseNo,
              colour: this.formObj.colour,
              standardCode: this.formObj.standardCode,
              materialFeedbackSubtableDto:deetailList,
            }
            add(info).then((res) => {
              this.request.post(res)
              this.$message({
                type: "success",
                message: res.data.msg,
              });
              this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value === this.$route.fullPath), 1);
              this.$router.push({path: '/quality/materialFeedback'});
            })
          }else{
            return false;
          }
        });
      });
    },
    //取消按钮
    resetForm() {
      this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value === this.$route.fullPath), 1);
      this.$router.push({path: '/quality/materialFeedback'});
    },
    //添加行号
    tableRowClassName({row, rowIndex}) {
      // 把每一行的索引放进row
      row.index = rowIndex
    },
    //列头加星号
    starAdd(obj) {
      if (obj.columnIndex === 2 || obj.columnIndex === 3 || obj.columnIndex === 7 || obj.columnIndex === 8 || obj.columnIndex === 9 || obj.columnIndex === 11) {
        return 'star';
      }
    },
    xiala() {
      const xx={
        pageNo: 1,
        pageSize: 100,
        total: 100,
      }
      list(xx).then(res => {
        console.log(66,res);
        this.parameterStandardList=res.data.data.items;
      });
    },
    //级联
    changeOptions(params) {
      const {code} = params;
      this.parameterStandardList.forEach(item=>{
        if( item.code === code){
          console.log("级联",item.code === code);
          this.formObj.standardName = item.name
          this.formObj.standardCode = code
        }
      })
    },
    changeXiaLa(){
      const xx = {
        pageNo: 1,
        pageSize: 100,
        total: 100,
        standardCode:this.formObj.standardCode
      }
      feedbackList(xx).then(res => {
        this.formObj.itemTable = [];
        res.data.data.items.forEach(v => {
          let item = {
            Id: "",
            sampleSize: "",
            productName: "",
            bomNo: "",
            purchaseNo: "",
            colour:"",
            realityMaterial: v.minValue===null&&v.maxValue===null?v.specificStandards===null?"":v.specificStandards:v.maxValue+"-"+v.minValue,
            standardCode: "",
            parameterCode: v.code,
            parameterName:v.name,
            type:v.type,
            inspectionMaterial: "",
            complianceWithStandards: "",
            evaluate: "",
          }
          this.formObj.itemTable.push(item);
        })
      })
    },
    //实时获取是否合格
    verification(type,realityMaterial,inspectionMaterial, index) {
      console.log("参数类型" + type);
      console.log("合格范围" + realityMaterial);
      console.log("检验值" + inspectionMaterial);
      //手动输入参数的长度为0的时候 检验数据为数值的时候
      if (type !== 0) {
        let indexRule = realityMaterial.split('-');
        console.log("截取字符串", indexRule);
        console.log("最大值", indexRule[0], "最小值", indexRule[1])
        //判断检验数据是否在参数标准中间
        if (indexRule[0] < inspectionMaterial) {//检验数据大于最大值 不合格
          this.formObj.itemTable[index].complianceWithStandards = "不合格";
          debugger
        } else if (indexRule[0] === inspectionMaterial) {//等于最大值 合格
          this.formObj.itemTable[index].complianceWithStandards = "合格"
        } else if (indexRule[0] > inspectionMaterial) {//小于最大值
          if (indexRule[1] > inspectionMaterial) {//检验数据 小于最小值 不合格
            this.formObj.itemTable[index].complianceWithStandards = "不合格";
          } else if (indexRule[1] === inspectionMaterial) {//等于最小值 合格
            this.formObj.itemTable[index].complianceWithStandards = "合格";
          } else if (indexRule[1] < inspectionMaterial) {//大于最小值 合格
            this.formObj.itemTable[index].complianceWithStandards = "合格";
          }
        }
      }
    },
    //详情
    edit(){
      this.routeSign = this.$route.query.sign;
      this.routeCode = this.$route.query.purchaseNo;
      const xx = {
        pageNo: 1,
        pageSize: 100,
        total: 100,
        purchaseNo: this.routeCode
      };
      console.log('xx', xx);
      chakan(xx).then(res => {
        console.log(res.data.data);
        const info = res.data.data.items;
        this.formObj.standardCode = info[0].standardCode;
        this.formObj.standardName = info[0].standardName;
        this.formObj.bomNo =info[0].bomNo;
        this.formObj.purchaseNo =info[0].purchaseNo;
        this.formObj.colour =info[0].colour;
        this.formObj.sampleSize =info[0].sampleSize;
        this.formObj.productName =info[0].productName;
        this.formObj.itemTable = info.map((v) => {
          return {
            ...v,
            complianceWithStandards: v.complianceWithStandards === null ? "" : v.complianceWithStandards === 1 ? "合格" : "不合格"
          }
        });
        console.log(this.data)
      })
    }
  }
}
</script>